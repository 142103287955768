import React from "react";
import PropTypes from 'prop-types';
import MRadioButton from "./MRadioButton";
import uniqueId from "lodash/uniqueId";
import {useTranslation} from "react-i18next";
import AErrorMessage from "../Atoms/AErrorMessage";
import ALabel from "../Atoms/ALabel";

const MGender = ({name, error, ...methods}) => {
    const {t} = useTranslation();
    return (
        <div className="row">
            <ALabel label={t("label.title")}/>
            <div className="col-12 col-md-6">
                <div className="row">
                    <MRadioButton {...methods}
                                  id={`m-gender_${uniqueId()}`}
                                  label={t("label.gender_male")}
                                  value={'male'}
                                  error={error}
                                  name={name}/>
                    <MRadioButton {...methods}
                                  id={`m-gender_${uniqueId()}`}
                                  label={t("label.gender_female")}
                                  value={'female'}
                                  error={error}
                                  name={name}/>
                    {error ? <AErrorMessage error={error} colWidth={'col-12'}/> : null}
                </div>

            </div>
        </div>
    )
};

MGender.propTypes = {
  name: PropTypes.string,
  error: PropTypes.object,
};

export default MGender;
