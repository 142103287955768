import React from "react";
import PropTypes from 'prop-types';

const ALabel = ({className, inputId, label}) => {

    const cssClasses = className ? className : "col-12 col-md-6";
    return (
        <label className={`a-label pl-0 ${cssClasses}`}
               htmlFor={inputId}>
            {label}
        </label>
    );
};

ALabel.propTypes = {
    className: PropTypes.string,
    inputId: PropTypes.string,
    label: PropTypes.string,
};

export default ALabel;
