import React from "react";
import PropTypes from 'prop-types';

import ALabel from "../Atoms/ALabel";
import AErrorMessage from "../Atoms/AErrorMessage";
import AInput from "../Atoms/AInput";


const MInput = ({label, inputId, name, error, onBlur, onChange, ...methods}) => {
    return (
        <div className="row pb-3">
            <ALabel inputId={inputId} label={label}/>
            <AInput {...methods}
                    name={name}
                    inputId={inputId}
                    error={error}
                    onBlur={onBlur}
                    onChange={onChange}
            />
            {error ? <AErrorMessage error={error} colWidth={'col-12 col-md-6'}/> : null}
        </div>
    )
};

MInput.propTypes = {
  label: PropTypes.string,
  inputId: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default MInput;
