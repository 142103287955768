import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import ALabel from "../Atoms/ALabel";

const urlPrefix = 'assets/files/erp/';
const urlAgb = 'agb#agb-s4';

const createDocumentLink = t => documentName => (
    <a href={documentName !== 'revocation_instructions' ?
        urlPrefix.concat(t(`terms_accepted.links.${documentName}`)) : urlAgb}
       target="_blank"
       rel="noopener noreferrer"
        className='m-checkbox__link'>
        {t(`terms_accepted.${documentName}`)}
    </a>
);


const MCheckbox = ({inputId, name, triggerValidation, register, onFirstDemand}) => {
    const {t} = useTranslation();
    const createLink = createDocumentLink(t);
    return (
        <div className="row m-checkbox_wrapper">
            <input className=" m-checkbox__input"
                   type="checkbox"
                   id={inputId}
                   name={name}
                   ref={register}
                   onChange={() => triggerValidation(name)}/>
            <ALabel className="col-12 m-checkbox__label" inputId={inputId} label={<span>{
                t('terms_accepted.description.first_part')
            } {
                createLink(onFirstDemand ? 'consultation_protocol_on_first_demand' : 'consultation_protocol')
            }, {
                createLink('product_information')
            }, {
                createLink('privacy_policy')
            }, {
                t('terms_accepted.description.and')
            } {
                createLink('revocation_instructions')
            } {
                t('terms_accepted.description.last_part')
            }</span>}/>
        </div>
    )
};

MCheckbox.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string,
  triggerValidation: PropTypes.func,
  register: PropTypes.func,
};

export default MCheckbox;
