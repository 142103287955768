import React from "react";
import PropTypes from 'prop-types';

const MImmutableDataItem = ({position, data}) => {
    return (
        <div className="row">
            <span className="col-12 col-md-6 m-immutable-data-item_label">{position}</span>
            <span className="col-12 col-md-6 m-immutable-data-item">{data}</span>
        </div>
    )
};

MImmutableDataItem.propTypes = {
  position: PropTypes.string,
  data: PropTypes.string,
};

export default MImmutableDataItem;
