import axios from 'axios';
import _ from 'lodash';
import {redirectTo} from "./redirections";


let backend = process.env.REACT_APP_BACKEND_URL;

if (backend == null) {
    backend = window.ERP_OA.REACT_APP_BACKEND_URL;
}

const URL = backend;

const load = async (hash) => {
    try {
        let error = false;
        const url = `${URL}/${hash}`;
        const response = await axios.get(url).catch(e => {
            error = true;
            return e.response.data.code});

        if (error) {
            return response;
        } else {
            const getAtPath = _.propertyOf(response);
            const data = getAtPath('data.data');
            if (data) {
                return data;
            }
        }
    } catch (e) {
        console.log(e)
    }

    return null;
};

const save = async (hash, data) => {
    const url = `${URL}/${hash}`;
    const response = await axios.post(url, data);
    return response;
};

const getStateOfApplicationEverySecond = (hash) => {
    const interval = setInterval(function () {
        getStateOfApplication(hash).then(state => {
            if (state !== 'submitted') {
                clearInterval(interval);
                redirectTo(state);
            }
        });
    }, 1000);
};

const getStateOfApplication = async (hash) => {
    const url = `${URL}/state/${hash}`;
    const response = await axios.get(url);
    return response.data.data.state;
};


export {
    load,
    save,
    getStateOfApplicationEverySecond,
};
