import React from "react";
import PropTypes from 'prop-types';
import ALabel from "../Atoms/ALabel";
import AErrorMessage from "../Atoms/AErrorMessage";
import { DatePicker } from "antd";
import 'antd/dist/antd.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/pro-light-svg-icons'
import {faCalendarAlt} from '@fortawesome/pro-light-svg-icons'


const checkmarkIcon = <FontAwesomeIcon size='2x' icon={faCheck}/>;
const calendarIcon = <FontAwesomeIcon size='2x' icon={faCalendarAlt}/>;


const MDateInput = ({inputId, label, name, register, setValue, triggerValidation, error, watch}) => {
    const dateFormat = 'DD.MM.YYYY';
    const watchDate = watch(name);

    return (
        <div className="row pb-3">
            <ALabel inputId={inputId} label={label}/>
            <div className='col-12 col-md-6'>
                <div className='row'>
                    <div className={`${error ? 'error' : 'valid'} col-12 p-0 m-date-input__wrapper`} data-testid="mDateInputWrapperTest">
                        <DatePicker
                            id={inputId}
                            style={{width: "100%"}}
                            name={name}
                            onChange={data =>
                                data ? setValue(name, data, true) : setValue(name, null, true)
                            }
                            onBlur={() => triggerValidation(name)}
                            format={dateFormat}
                            defaultValue={null}
                            showToday={false}
                            placeholder="tt.mm.jjjj"
                            ref={register({name: name})}
                            value={watchDate}
                            suffixIcon={calendarIcon}
                        />
                    </div>
                    {error ? '' : <span className='m-date-input__icon valid'>{checkmarkIcon} </span> }
                </div>
            </div>
            {error ? <AErrorMessage error={error} colWidth={'col-12 col-md-6'}/> : null}
        </div>
    );
};

MDateInput.propTypes = {
  inputId: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  triggerValidation: PropTypes.func,
  error: PropTypes.object,
  watch: PropTypes.func,
};

export default MDateInput;
