import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const APersonalGreeting = ({name}) => {
    const {t} = useTranslation();

    return (
        <div className='col-12'>
            <h4 className='a-personal-greeting__align-center salutation'>
                {t('personal_greeting.salutation', {name: name})}
            </h4>
            <div className='a-personal-greeting__align-center success-message'>
                {t('personal_greeting.success_message')}
            </div>
        </div>
    )
};


APersonalGreeting.propTypes = {
  name: PropTypes.string,
};

export default APersonalGreeting;
