import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ASectionTitle from '../Atoms/ASectionTitle';
import ADataTitle from '../Atoms/ADataTitle';
import MImmutableDataItem from '../Molecule/MImmutableDataItem';

const OVerifyData = ({firstTenant, secondTenant, apartment, lessor, showSecondTenant}) => {
  const {t} = useTranslation();

  return (
      <div className="col-12 pb-5">
        <ASectionTitle title={t('check.section_title')}
                       description={t('check.description_start')}
                       descriptionEnd={t('check.description_end')}/>
        <ADataTitle dataTitle={t('tenant_data.your_data')}/>
        <MImmutableDataItem position={t('label.name')}
                            data={firstTenant.name}/>
        {showSecondTenant ? <div>
          <ADataTitle dataTitle={t('tenant_data.second_tenant')}/>
          <MImmutableDataItem position={t('label.name')}
                              data={secondTenant?.name}/>
        </div> : null}
        <ADataTitle dataTitle={t('check.your_new_flat')}/>
        <MImmutableDataItem position={t('label.address')}
                            data={apartment.address}/>
        <MImmutableDataItem position={t('label.deposit_sum')}
                            data={apartment.deposit_sum}/>
        <MImmutableDataItem position={t('label.rental_date')}
                            data={apartment.rental_date}/>
        <ADataTitle dataTitle={'Ihr Vermieter'}/>
        <MImmutableDataItem position={t('label.company_or_name')}
                            data={lessor.name}/>
        <MImmutableDataItem position={t('label.address')}
                            data={lessor.address}/>
      </div>
  );
};

OVerifyData.propTypes = {
  firstTenant: PropTypes.object,
  secondTenant: PropTypes.object,
  apartment: PropTypes.object,
  lessor: PropTypes.object,
  showSecondTenant: PropTypes.object,
};

export default OVerifyData;
