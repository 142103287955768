import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import nationalities from '../../utilities/nationalities';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import ALabel from '../Atoms/ALabel';
import AErrorMessage from '../Atoms/AErrorMessage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-light-svg-icons';
import {faPencilAlt} from '@fortawesome/pro-light-svg-icons';

const editIcon = <FontAwesomeIcon size='2x' icon={faPencilAlt}/>;
const checkmarkIcon = <FontAwesomeIcon size='2x' icon={faCheck}/>;

const selectView = t => ({countryCode}) => {
  if (countryCode === '') {
    return null;
  }
  return {value: countryCode, label: t(`nationalities.${countryCode}`)};
};

const filteredOptions = (value) => nationalities.filter((nationality) => {
  const matchCountyCode = nationality.countryCode === value.toUpperCase();
  const matchCountryName = nationality.altName.match(
      new RegExp(value, 'i'),
  );
  return matchCountyCode || matchCountryName;
});

const getComparable = _.memoize((val) => _.upperCase(_.deburr(val)));

const byLabelSort = (a, b) => {
  const labelA = getComparable(a.label);
  const labelB = getComparable(b.label);
  if (labelA > labelB) {
    return 1;
  }

  if (labelA < labelB) {
    return -1;
  }

  return 0;
};

const loadOptions = view => (inputValue, callback) => {
  callback(filteredOptions(inputValue).map(view).sort(byLabelSort));
};

const MNationalitySelect = ({register, setValue, name, label, inputId, error, triggerValidation, watch}) => {
  const {t} = useTranslation();
  const translateView = selectView(t);
  const watchSelect = watch(name); // supply default value as second argument

  const handleChange = (data) => {
    return data ? setValue(name, translateView({countryCode: data.value}).value,
        true) : setValue(name, '', true);
  };

  return (
      <div className="row pb-3">
        <ALabel inputId={inputId} label={label}/>
        <div className='col-12 col-md-6'>
          <div className='row' data-testid='asyncSelectMNationalities'>
            <AsyncSelect
                className={`${error
                    ? 'error'
                    : 'valid'} col-12 pl-0 pr-0 m-nationality-select__inputfield`}
                id={inputId}
                name={name}
                onChange={data => handleChange(data)}
                defaultOptions
                loadOptions={loadOptions(translateView)}
                ref={register({name: name})}
                onBlur={() => triggerValidation(name)}
                value={{
                  label: translateView(
                      {countryCode: watchSelect ?? 'none'})?.label,
                }}
            />
            {error
                ? <span
                    className='m-nationality-select__icon error'>{editIcon} </span>
                :
                <span
                    className='m-nationality-select__icon valid'>{checkmarkIcon} </span>}
          </div>
        </div>
        {error
            ? <AErrorMessage error={error} colWidth={'col-12 col-md-6'}/>
            : null}
      </div>
  );
};

MNationalitySelect.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  inputId: PropTypes.string,
  error: PropTypes.object,
  triggerValidation: PropTypes.func,
  watch: PropTypes.func,
};

export default MNationalitySelect;
export {
  selectView,
};
