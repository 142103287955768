import {getHash} from "./hasher";
import {getStateOfApplicationEverySecond, save} from "./backendConnection";
import {redirectTo} from "./redirections";
import moment from 'moment';

export const onSubmit = (state, setFormSent, setShowSpinner, setshowAnswerDelayedInformation) => (data) => {
    const hash = getHash();

    const timings = window?.ERP_OA?.REACT_APP_TIMINGS;
    const delayInformationTimeOut = timings?.TIP ? timings?.TIP : 5000;
    const redirectTimeOut = timings?.REDIRECT ? timings?.REDIRECT : 10000;

    if (!state.immutableData.secondTenant) {
        delete data.secondTenant;
    }

    if (data?.firstTenant?.dateOfBirth) {
        data.firstTenant.dateOfBirth = moment(data.firstTenant.dateOfBirth).format('DD.MM.YYYY');
    }

    if (data?.secondTenant?.dateOfBirth) {
        data.secondTenant.dateOfBirth = moment(data.secondTenant.dateOfBirth).format('DD.MM.YYYY');
    }

    save(hash, data, setShowSpinner).then(response => {
        setFormSent(true);
        getStateOfApplicationEverySecond(hash);
        setTimeout(showDelayInformation, delayInformationTimeOut);
        setTimeout(goToProcessingPage, redirectTimeOut);
    }).catch(error => {
        setFormSent(false);
        redirectTo('error');
    });

    const showDelayInformation = () => {
        setShowSpinner(false);
        setshowAnswerDelayedInformation(true);
    };

    const goToProcessingPage = () => {
        redirectTo('submitted_pending');
    };
};
