import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from './../translations/de';

// the translations
// (tip move them in a JSON file and import them)
// const resources = {
//   en: {
//     translation: {
//       "first": {
//         "header": 'Hello World'
//       }
//     }
//   },
//   de: {
//     translation: {
//       "first": {
//         "header": 'Hallo Welt'
//       }
//     }
//   }
// };

i18n
.use(initReactI18next) // passes i18n down to react-i18next
      .init({
      resources,
      lng: "de",

      interpolation: {
        escapeValue: false // react already safes from xss

      }
    });

export default i18n;