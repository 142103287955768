import React from "react";
import PropTypes from 'prop-types';

const ASpinner = ({title}) => {
    return (
        <div>
            {title && <p className="a-spinner__title"> {title} </p>}
            <div className="a-spinner-fading-circle">
                <div className="a-spinner-circle1 a-spinner-circle"/>
                <div className="a-spinner-circle2 a-spinner-circle"/>
                <div className="a-spinner-circle3 a-spinner-circle"/>
                <div className="a-spinner-circle4 a-spinner-circle"/>
                <div className="a-spinner-circle5 a-spinner-circle"/>
                <div className="a-spinner-circle6 a-spinner-circle"/>
                <div className="a-spinner-circle7 a-spinner-circle"/>
                <div className="a-spinner-circle8 a-spinner-circle"/>
                <div className="a-spinner-circle9 a-spinner-circle"/>
                <div className="a-spinner-circle10 a-spinner-circle"/>
                <div className="a-spinner-circle11 a-spinner-circle"/>
                <div className="a-spinner-circle12 a-spinner-circle"/>
            </div>
        </div>
    );
};

ASpinner.propTypes = {
    title: PropTypes.string,
};

export default ASpinner;
