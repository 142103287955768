import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import ValidationSchema, {defaultInitialImmutableValues} from '../../config/ValidationSchema';
import ValidationOneTenantSchema from '../../config/ValidationOneTenantSchema';
import {selectView} from '../Molecule/MNationalitySelect';
import '../../lib/i18n';
import OTenantData from '../organisms/OTenantData';
import OPayment from '../organisms/OPayment';
import OBankAccount from '../organisms/OBankAccount';
import OTermsAccept from '../organisms/OTermsAccept';
import OVerifyData from '../organisms/OVerifyData';
import {useTranslation} from 'react-i18next';
import {setFormData} from '../../utilities/setFormData';
import {onSubmit} from '../../utilities/onSubmit';
import ABlockInformation from '../Atoms/ABlockInformation';
import ACompletionButton from '../Atoms/ACompletionButton';
import APersonalGreeting from '../Atoms/APersonalGreeting';
import AModal from '../Atoms/AModal';
import ASpinner from '../Atoms/ASpinner';

const App = () => {
  const [state, setState] = useState({immutableData: defaultInitialImmutableValues});
  const [formSent, setFormSent] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAnswerDelayedInformation, setshowAnswerDelayedInformation] = useState(false);
  const [applicationNotFound, setApplicationNotFound] = useState(false);
  const [applicationGone, setApplicationGone] = useState(false);
  const data = state.immutableData;
  const methods = useForm({
    validationSchema: data.secondTenant ? ValidationSchema : ValidationOneTenantSchema,
  });
  const formState = methods.formState;
  const formHasError = formState.isSubmitted && !formState.isValid;
  const {t} = useTranslation();
  const translateView = selectView(t);
  useEffect(() => {
    const fetchData = async () => {
      const formDataSet = await setFormData(methods, translateView, setState);
      if (formDataSet === 'NOFOUND' || formDataSet === 'NOTFOUND' || formDataSet === 'UNKNOWN') {
        setApplicationNotFound(true);
      }
      if (formDataSet === 'GONE') {
        setApplicationGone(true);
      }
    };
    fetchData().catch(e => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (formHasError && showSpinner) {
      setShowSpinner(false);
    }
  }, [formHasError, showSpinner]);

  return (
      <div className="container">
        <form id="formId" className="row app_form-wrapper"
              onSubmit={methods.handleSubmit(
                  onSubmit(state, setFormSent, setShowSpinner, setshowAnswerDelayedInformation))}>
          <APersonalGreeting name={data.firstTenant.name}/>
          <OVerifyData {...data}
                       showSecondTenant={data.secondTenant}/>
          <OTenantData {...methods}
                       nameFirstTenant={data.firstTenant.name}
                       nameSecondTenant={data.secondTenant?.name}
                       showSecondTenant={data.secondTenant}/>
          <OPayment {...methods}
                    paymentConditions={data.paymentConditions}/>
          <OBankAccount {...methods}/>
          <OTermsAccept {...methods} onFirstDemand={data.onFirstDemand}/>

          {formSent ? <ABlockInformation message={'application.sent_successfully'}
                                         color={'a-block-information_green'}/> : null}
          {(formHasError) ?
              <ABlockInformation message={'validation.error.overall'}
                                 color={'a-block-information_red'}/>
              : null}

          <ACompletionButton onclick={() => setShowSpinner(true)}/>

          {showSpinner ? <AModal> <ASpinner title={t('modal.title_spinner')}/> </AModal> : null}
          {showAnswerDelayedInformation ?
              <AModal><p>{t('modal.answer_delayed_information')}</p></AModal> : null}
          {applicationNotFound ?
              <AModal notTransparent={true}><p className="a-modal__center-text"> {t('modal.application_not_found')} </p>
              </AModal> : null}
          {applicationGone ?
              <AModal notTransparent={true}><p className="a-modal__center-text"> {t('modal.application_already_processed')} </p></AModal> : null}
        </form>
      </div>
  );
};

export default App;
