import querystring from 'query-string'

export const getHash = () => {
  // read CID from config if it's set
  if (window.ERP_OA && window.ERP_OA.REACT_APP_CID) {
    return window.ERP_OA.REACT_APP_CID;
  }
  // otherwise get it from the URL parameters
  // eslint-disable-next-line no-restricted-globals
  const params = querystring.parse(location.search);
  if (params && params['cid']) {
    return params.cid;
  }

  return undefined;
};

