import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ASectionTitle from "../Atoms/ASectionTitle";
import MTenant from "../Molecule/MTenant";


const OTenantData = ({nameFirstTenant, nameSecondTenant, showSecondTenant, ...methods}) => {
    const {t} = useTranslation();
    const validateEmails = () => {
        methods.triggerValidation('firstTenant.email');
        methods.triggerValidation('secondTenant.email');
    };

    return (
        <div className="col-12 pb-5">
            <ASectionTitle title={t('tenant_data.section_title')}
                           description={t('tenant_data.description')}/>
            <MTenant {...methods}
                     nameTenant={nameFirstTenant}
                     tenant="firstTenant"
                     onBlurEmail={validateEmails}
            />
            {showSecondTenant ? <MTenant {...methods}
                                         nameTenant={nameSecondTenant}
                                         tenant="secondTenant"
                                         onBlurEmail={validateEmails}
            /> : null}
        </div>
    );
};

OTenantData.propTypes = {
  nameFirstTenant: PropTypes.string,
  nameSecondTenant: PropTypes.string,
  showSecondTenant: PropTypes.object,
};

export default OTenantData;
