export const redirectTo = (state) => {
    const kirbyRedirects = window?.ERP_OA?.REACT_APP_REDIRECTS;
    const acceptedPage = kirbyRedirects?.accepted ? kirbyRedirects?.accepted : 'antrag/glueckwunsch';
    const declinedPage = kirbyRedirects?.declined ? kirbyRedirects?.declined : 'antrag/sorry';
    const submittedPendingPage = kirbyRedirects?.submitted_pending ? kirbyRedirects?.submitted_pending : 'antrag/warten';
    const noWaitPage = kirbyRedirects?.nowait ? kirbyRedirects?.nowait : 'antrag/hurryup';
    const errorPage = kirbyRedirects?.error ? kirbyRedirects?.error : 'antrag/fehler';

    const stateOfApplicationMap = new Map();
    stateOfApplicationMap.set('accepted', acceptedPage);
    stateOfApplicationMap.set('declined', declinedPage);
    stateOfApplicationMap.set('submitted_pending', submittedPendingPage);
    stateOfApplicationMap.set('nowait', noWaitPage);
    stateOfApplicationMap.set('error', errorPage);

    window.location.assign(stateOfApplicationMap.get(state) ? stateOfApplicationMap.get(state) : errorPage);
};
