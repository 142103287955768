import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import MRadioButton from '../Molecule/MRadioButton';
import uniqueId from 'lodash/uniqueId';
import ASectionTitle from '../Atoms/ASectionTitle';
import AErrorMessage from '../Atoms/AErrorMessage';

const OPayment = ({errors, paymentConditions, ...methods}) => {
  const {t} = useTranslation();
  const createDescription = (paymentInterval) => {
    return t('payment.description',
        {percentage: paymentConditions[paymentInterval].percentage});
  };
  const createLabel = (paymentInterval) => {
    return t(`payment.label.${paymentInterval}`, {
      rate: paymentConditions[paymentInterval].rate,
      monthlyRate: paymentConditions[paymentInterval].monthlyRate,
    });
  };

  return (
      <div className="col-12 pb-5">
        <ASectionTitle title={t('payment.section_title')}/>

        <div className="row pt-2">
          <MRadioButton {...methods}
                        id={`o-payment_${uniqueId()}`}
                        label={createLabel('monthly')}
                        description={createDescription('monthly')}
                        value='monthly'
                        name="payment"
                        error={errors?.payment}
          />
          <MRadioButton {...methods}
                        id={`o-payment_${uniqueId()}`}
                        label={createLabel('yearly')}
                        description={createDescription('yearly')}
                        value='yearly'
                        name="payment"
                        error={errors?.payment}
          />
        </div>
        <div className="row pt-2">
          <MRadioButton {...methods}
                        id={`o-payment_${uniqueId()}`}
                        label={createLabel('semi_annually')}
                        description={createDescription('semi_annually')}
                        name="payment"
                        value='semi_annually'
                        error={errors?.payment}
          />
          <MRadioButton {...methods}
                        id={`o-payment_${uniqueId()}`}
                        label={createLabel('quarterly')}
                        description={createDescription('quarterly')}
                        value='quarterly'
                        name="payment"
                        error={errors?.payment}
          />
          {errors?.payment ? <AErrorMessage error={errors?.payment}
                                            colWidth={'col-12'}/> : null}
        </div>
      </div>
  );
};

OPayment.propTypes = {
  errors: PropTypes.object,
  paymentConditions: PropTypes.object,
};

export default OPayment;
