import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const AErrorMessage = ({error, colWidth}) => {
  const {t} = useTranslation();

  return (
      <div className="a-error-message__wrapper">
        <div className={`${colWidth} pl-1 pt-2`}>
          {error ? <p> {t(error.message)}</p> : null}
        </div>
      </div>
  );
};

AErrorMessage.propTypes = {
  error: PropTypes.object,
  colWidth: PropTypes.string,
};

export default AErrorMessage;
