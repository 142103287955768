import {flattenObject} from "./flattenObject";
import _ from "lodash";
import moment from "moment";
import {immutableDataValidationSchema} from "../config/ValidationSchema";
import {getHash} from "./hasher";
import {load} from "./backendConnection";
import {setBankName} from "./bankName";

export const setFormData = async function (methods, translateView, setState) {
    const hash = getHash();
    let dataStatus = 'NOHASH'
    if (hash) {
        await load(hash).then(data => {
            if (data === 'NOFOUND' || data === 'NOTFOUND' || data === 'UNKNOWN' ) {
                dataStatus = 'NOFOUND';
            } else if (data === 'GONE') {
                dataStatus = 'GONE'
            } else {
                const jsonData = flattenObject(data);
                for (let entry in jsonData) {
                    const value = _.get(jsonData, entry);

                    if (!entry.startsWith('immutableData')) {
                        if (entry.endsWith('dateOfBirth')) {
                            methods.setValue(`${entry}`, value ? moment(value) : null, true)
                        } else {
                            methods.setValue(`${entry}`, value, true);
                        }
                    }
                }

                const bankName = _.get(jsonData, 'bankAccount.bankName');
                const ibanError = methods.errors?.bankAccount?.iban;
                if (!bankName && !ibanError) {
                    setBankName(methods.getValues, methods.setValue).then();
                }

                /**
                 * when firstTenant.email is blank and secondTenant.email is set
                 * but not yet put to the form data firstTenant.email is
                 * validated as invalid. To re-validate firstTenant.email we
                 * reset the value
                 */
                const firstTenantEmailKey = 'firstTenant.email';
                if (firstTenantEmailKey in jsonData) {
                    methods.setValue(firstTenantEmailKey, jsonData[firstTenantEmailKey], true);
                }

                immutableDataValidationSchema.validate(data.immutableData)
                    .then(valid => {
                        if (valid) {
                            setState({
                                immutableData: data.immutableData
                            });
                        }
                    })
                    .catch(console.log);
                dataStatus = 'FOUND'
            }
        }).catch(console.log);
    }
    return dataStatus;
};
