import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const ACompletionButton = ({onclick}) => {
    const {t} = useTranslation();

    return (
        <div className='col-12'>
            <div className='row a-completion-button__wrapper'>
                <hr className="a-completion-button__line"/>
                <button className='a-completion-button__input' type="submit" onClick={onclick}>
                    {t('terms_accepted.completion_button')}
                </button>
            </div>
        </div>
    )
};
ACompletionButton.propTypes = {
  onclick: PropTypes.func,
};

export default ACompletionButton
