import React from 'react';
import PropTypes from 'prop-types';
import ADataTitle from '../Atoms/ADataTitle';
import MInput from './MInput';
import uniqueId from 'lodash/uniqueId';
import {useTranslation} from 'react-i18next';
import MNationalitySelect from './MNationalitySelect';
import MDateInput from './MDateInput';
import MAddressField from './MAddressField';
import MGender from './MGender';

const MTenant = ({tenant, nameTenant, onBlurEmail, ...methods}) => {
  const {t} = useTranslation();
  const tenantError = tenant === 'firstTenant'
      ? methods.errors?.firstTenant
      : methods.errors?.secondTenant;
  const tenantTitle = tenant === 'firstTenant'
      ? 'tenant_data.your_data'
      : 'tenant_data.second_tenant';

  return (
      <div className="row">
        <div className="col-12">
          <ADataTitle dataTitle={t(tenantTitle)} nameTenant={nameTenant}/>
          <MGender {...methods}
                   name={`${tenant}.gender`}
                   error={tenantError?.gender}/>
          <MInput {...methods}
                  error={tenantError?.email}
                  label={t('label.email_address')}
                  name={`${tenant}.email`}
                  onBlur={onBlurEmail}
                  onChange={onBlurEmail}
                  inputId={`m-input_${uniqueId()}`}/>
          <MInput {...methods}
                  error={tenantError?.phoneNumber}
                  label={t('label.phone_number')}
                  name={`${tenant}.phoneNumber`}
                  inputId={`m-input_${uniqueId()}`}/>
          <MNationalitySelect {...methods}
                              error={tenantError?.nationality}
                              name={`${tenant}.nationality`}
                              label={t('label.nationality')}
                              inputId={`m-nationality-select_${uniqueId()}`}/>
          <MDateInput {...methods}
                      error={tenantError?.dateOfBirth}
                      name={`${tenant}.dateOfBirth`}
                      label={t('label.date_of_birth')}
                      inputId={`m-date-input_${uniqueId()}`}/>
          {tenant === 'firstTenant' ? <ADataTitle
              dataTitle={t('tenant_data.current_address')}/> : null}
          <MAddressField {...methods}
                         errorFirstInput={tenantError?.address?.street}
                         errorSecondInput={tenantError?.address?.streetNumber}
                         label={t('label.street_and_number')}
                         nameFirstInput={`${tenant}.address.street`}
                         nameSecondInput={`${tenant}.address.streetNumber`}
                         inputId={`m-address-fields_${uniqueId()}`}
                         colFirstInput={'col-12 col-md-8'}
                         colSecondInput={'col-12 col-md-4'}/>
          <MAddressField {...methods}
                         errorFirstInput={tenantError?.address?.zip}
                         errorSecondInput={tenantError?.address?.city}
                         label={t('label.zip_and_city')}
                         nameFirstInput={`${tenant}.address.zip`}
                         nameSecondInput={`${tenant}.address.city`}
                         inputId={`m-address-fields_${uniqueId()}`}
                         colFirstInput={'col-12 col-md-4'}
                         colSecondInput={'col-12 col-md-8'}/>
        </div>
      </div>
  );
};

MTenant.propTypes = {
  tenant: PropTypes.string,
  nameTenant: PropTypes.string,
  onBlurEmail: PropTypes.func,
};

export default MTenant;
