export const flattenObject = object => {
    let flattenedObject = {};
    for (const property in object) {
        if (!object.hasOwnProperty(property)) {
            continue;
        }

        if (object[property] !== null && (typeof object[property]) === 'object') {
            let flattenedSubObject = flattenObject(object[property]);
            for (const subProperty in flattenedSubObject) {
                if (!flattenedSubObject.hasOwnProperty(subProperty)) {
                    continue;
                }

                flattenedObject[property + '.' + subProperty] = flattenedSubObject[subProperty];
            }
        } else {
            flattenedObject[property] = object[property];
        }
    }
    return flattenedObject;
};
