import moment from 'moment';
import * as Yup from 'yup';
import IBAN from 'iban';


const emailSchema = Yup.string().email('validation.error.email');
const phoneNumberSchema = Yup.string()
    .required('validation.required')
    .matches(/^(\+[0-9]{1,2}|0[0-9]{2,4})[0-9]+$/,
        'validation.error.phone_number')
    .min(6, 'validation.error.phone_number');

const nationalitySchema = Yup.string().typeError('validation.required').required('validation.required');
const dateOfBirthSchema = Yup.date()
    .typeError('validation.required')
    .required('validation.required')
    .max(moment().subtract(18, 'years').toDate(),
        'validation.error.date_of_birth.upper_bound')
    .min(moment('1900-01-01').toDate(),
        'validation.error.date_of_birth.lower_bound');

const addressSchema = Yup.object().shape({
  street: Yup.string().required('validation.required'),
  streetNumber: Yup.string()
      .required('validation.required')
      .matches(/.*[0-9].*/, 'validation.error.street_number'),
  city: Yup.string().required('validation.required'),
  zip: Yup.string()
      .required('validation.required')
      .min(5, 'validation.error.zip.length')
      .max(5, 'validation.error.zip.length')
      .matches(/[0-9]{5}/, 'validation.error.zip.format'),
});

export const ibanSchema = Yup.string()
    .required('validation.required')
    .test('isValidIban', 'validation.error.iban', IBAN.isValid);

export const tenantSchema = emailRequired => Yup.object().shape({
  gender: Yup.string().required('validation.required'),
  email: emailRequired
      ? emailSchema.required('validation.required')
      : emailSchema,
  phoneNumber: phoneNumberSchema,
  dateOfBirth: dateOfBirthSchema,
  nationality: nationalitySchema,
  address: addressSchema,
});

export default Yup.object().shape({
  firstTenant: Yup.object()
      .when('secondTenant.email', (secondTenantEmail, _) => {
        return tenantSchema(secondTenantEmail === '');
      }),
  secondTenant: tenantSchema(false),
  bankAccount: Yup.object().shape({
    iban: ibanSchema,
    bankName: Yup.string().required('validation.required'),
    holderName: Yup.string().required('validation.required'),
  }),
  payment: Yup.string().required('validation.required'),
  termsAccepted: Yup.bool().oneOf([true], 'validation.error.terms_accepted'),
});

const tenantData = Yup.object().shape({
  title: Yup.string(),
  name: Yup.string(),
}).default(null);

export const immutableDataValidationSchema = Yup.object().shape({
  firstTenant: tenantData.required('First tenant is missing'),
  secondTenant: tenantData.nullable(),
  apartment: Yup.object().default(null).shape({
    address: Yup.string(),
    deposit_sum: Yup.string(),
    monthly_rent: Yup.string(),
    rental_data: Yup.string(),
  }).required('Apartment data is missing'),
  lessor: Yup.object().shape({
    name: Yup.string(),
    address: Yup.string(),
  }).required('Lessor missing'),
});

export const defaultInitialImmutableValues = {
  onFirstDemand: false,
  firstTenant: {
    name: '',
  },
  secondTenant: {
    name: '',
  },
  apartment: {
    address: '',
    deposit_sum: '',
    monthly_rent: '',
    rental_date: '',
  },
  lessor: {
    name: '',
    address: '',
  },
  paymentConditions: {
    monthly: {
      rate: '',
      percentage: '',
    },
    yearly: {
      rate: '',
      percentage: '',
    },
    semi_annually: {
      rate: '',
      percentage: '',
    },
    quarterly: {
      rate: '',
      percentage: '',
    },
  },
};
