import React from "react";
import PropTypes from 'prop-types';

const AModal = (props) => {
    return (
        <div className={`a-modal ${props.notTransparent ? "a-modal__not-transparent" : "a-modal__transparent"}`}>
            <div className='a-modal__inner'>
                <div className='a-modal__content'>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

AModal.propTypes = {
  props: PropTypes.element,
};

export default AModal;
