export default {
    "de": {
        "translation": {
            "validation": {
                "required": "Bitte angeben!",
                "error": {
                    "email": "Ihre Eingabe scheint kein gültiges Email-Format zu haben. Prüfen Sie Ihre Eingabe.",
                    "phone_number": "Ihre Eingabe scheint kein gültiges Format zu haben. Angabe im Format: 03012345 oder +493012345",
                    "date_of_birth": {
                        "format": "Geben Sie ein gültiges Datum ein im Format TT.MM.JJJJ",
                        "upper_bound": "Volljährigkeit zum Vertragsabschluss erforderlich",
                        "lower_bound": "muss nach dem 01.01.1900 liegen",
                    },
                    "street_number": "ungültig",
                    "zip": {
                        "format": "PLZ darf nur Zahlen enthalten",
                        "length": "PLZ muss 5 stellig sein",
                    },
                    "iban": "IBAN ist ungültig. Prüfen Sie Ihre Eingabe.",
                    "terms_accepted": "Um fortzufahren, benötigen wir Ihre Zustimmung.",
                    "overall": "Es sind noch nicht alle Felder richtig ausgefüllt. Bitte verbessern Sie die Eingabefelder, die jetzt rot markiert oder leer sind."
                },
            },
            "label": {
                "name": "Name",
                "company_or_name": "Firma oder Name",
                "address": "Adresse",
                "monthly_rent": "Monatskaltmiete",
                "deposit_sum": "Mietkautionssumme",
                "rental_date": "Beginn Mietvertrag",
                "title": "Anrede",
                "gender_male": "Herr",
                "gender_female": "Frau",
                "email_address": "E-Mail Adresse",
                "phone_number": "Telefonnummer",
                "nationality": "Nationalität",
                "date_of_birth": "Geburtsdatum",
                "street_and_number": "Straße und Hausnummer",
                "zip_and_city": "PLZ und Stadt",
                "account_holder": "Kontoinhaber",
                "iban": "IBAN",
                "bank_name": "Kreditinstitut",
                "save": "Kostenpflichtig abschließen"
            },
            "check": {
                "section_title": "1. Daten Überprüfen",
                "description_start": "Bitte überprüfen Sie die folgenden Felder auf Ihre Richtigkeit. Sollten Angaben nicht richtig übertragen worden sein, ",
                "description_end": "wenden Sie sich bitte an Ihren Verwalter.",
                "your_new_flat": "Ihre neue Wohnung",
            },
            "personal_greeting": {
                "salutation": "Herzlichen Glückwunsch {{name}},",
                "success_message": "Ihre Daten wurden erfolgreich übertragen."
            },
            "tenant_data": {
                "section_title": "2. Daten anpassen",
                "description": "Bitte überprüfen Sie folgende Daten. Sollten Sie diese ändern wollen, können Sie das direkt hier im Antrag tun.",
                "your_data": "Ihre Daten",
                "current_address": "Aktuelle Meldeadresse",
                "second_tenant": "Zweiter Mieter"
            },
            "payment": {
                "section_title": "3. Zahlweise auswählen",
                "label": {
                    "monthly": "monatlich {{rate}}",
                    "quarterly": "vierteljährlich {{rate}}, entspricht {{monthlyRate}} pro Monat",
                    "semi_annually": "halbjährlich {{rate}}, entspricht {{monthlyRate}} pro Monat",
                    "yearly": "jährlich {{rate}}, entspricht {{monthlyRate}} pro Monat",
                },
                "description": "( {{percentage}} der Mietkaution p.A.)"
            },
            "bank_account": {
                "section_title": "4. Bankverbindung",
                "description": "Bitte überprüfen Sie folgende Daten. Sollten Sie diese ändern wollen, können Sie das direkt hier im Antrag tun.",
            },
            "terms_accepted": {
                "section_title": "5. Vertragsbedingungen",
                "description": {
                    "first_part": "Mit dem Klick auf \"Kostenpflichtig abschließen\" " +
                        "bestätigen Sie, dass Sie die Dokumente",
                    "and": "und",
                    "last_part": "zur Kenntnis genommen haben und die " +
                        "Kautionsbürgschaft zu den darin enthaltenen Bedingungen abschließen " +
                        "möchten."
                },
                "links": {
                    "consultation_protocol": "Beratungsprotokoll.pdf",
                    "consultation_protocol_on_first_demand": "Beratungsprotokoll_EA.pdf",
                    "product_information": "Produktinformationsblatt.pdf",
                    "policy_conditions": "Versicherungsbedingungen.pdf",
                    "privacy_policy": "Datenschutzbestimmungen.pdf",
                    "revocation_instructions": "Widerrufsbelehrung.pdf",
                },
                "consultation_protocol": "Beratungsprotokoll",
                "consultation_protocol_on_first_demand": "Beratungsprotokoll",
                "product_information": "Produktinformationsblatt",
                "policy_conditions": "Versicherungsbedingungen",
                "privacy_policy": "Datenschutzbestimmungen",
                "revocation_instructions": "Widerrufsbelehrung",
                "completion_button": "Kostenpflichtig abschließen",
            },
            "modal": {
                "title_spinner": "Ihre Anfrage wird bearbeitet. Bitte warten Sie einen Augenblick.",
                "answer_delayed_information": "Dies dauert ein wenig länger als normal. Sie brauchen hier nicht zu warten. Sie können den Browser schließen und wir werden Sie per E-Mail benachrichtigen.",
                "application_not_found": "Wir konnten Ihren Antrag leider nicht finden. Bitte melden Sie sich unter 0211 54 26 83 11",
                "application_already_processed": "Ihr Antrag wurde bereits bearbeitet. Sollten Sie weitere Fragen zu diesem haben, melden Sie sich bitte unter 0211 54 26 83 11."
            },
            "application": {
                "sent_successfully": "Ihr Antrag wurde erfolgreich versendet. Bitte warten Sie einen Moment auf das Ergebnis.",
            },
            "nationalities": {
                "none": 'Bitte wählen...',
                "AF": "Afghanistan",
                "AL": "Albanien",
                "AQ": "Antarktis",
                "DZ": "Algerien",
                "AS": "Amerikanischen Samoa-Inseln",
                "AD": "Andorra",
                "AO": "Angola",
                "AG": "Antigua und Barbuda",
                "AZ": "Aserbaidschan",
                "AR": "Argentinien",
                "AU": "Australien",
                "AT": "Österreich",
                "BS": "Bahamas",
                "BH": "Bahrein",
                "BD": "Bangladesch",
                "AM": "Armenien",
                "BB": "Barbados",
                "BE": "Belgien",
                "BM": "Bermuda",
                "BT": "Bhutan",
                "BO": "Bolivien",
                "BA": "Bosnien und Herzegowina",
                "BW": "Botswana",
                "BV": "Bouvet-Insel",
                "BR": "Brasilien",
                "BZ": "Belize",
                "IO": "Britisches Territorium des Indischen Ozeans",
                "SB": "Salomonen",
                "VG": "Britische Jungferninseln",
                "BN": "Brunei Darussalam",
                "BG": "Bulgarien",
                "MM": "Myanmar",
                "BI": "Burundi",
                "BY": "Weißrussland",
                "KH": "Kambodscha",
                "CM": "Kamerun",
                "CA": "Kanada",
                "CV": "Kap Verde",
                "KY": "Cayman Inseln",
                "CF": "Zentralafrikanische Republik",
                "LK": "Sri Lanka",
                "TD": "Tschad",
                "CL": "Chile",
                "CN": "China",
                "TW": "Taiwan",
                "CX": "Weihnachtsinsel",
                "CC": "Cocos (Keeling) Islands",
                "CO": "Kolumbien",
                "KM": "Komoren",
                "YT": "Mayotte",
                "CG": "Kongo",
                "CD": "Demokratische Republik Kongo",
                "CK": "Cookinseln",
                "CR": "Costa Rica",
                "HR": "Kroatien",
                "CU": "Kuba",
                "CY": "Zypern",
                "CZ": "Tschechische Republik",
                "BJ": "Benin",
                "DK": "Dänemark",
                "DM": "Dominica",
                "DO": "Dominikanische Republik",
                "EC": "Ecuador",
                "SV": "El Salvador",
                "GQ": "Äquatorialguinea",
                "ET": "Äthiopien",
                "ER": "Eritrea",
                "EE": "Estland",
                "FO": "Färöer Inseln",
                "FK": "Falklandinseln (Malvinas)",
                "GS": "Süd-Georgien und die südlichen Sandwich-Inseln",
                "FJ": "Fidschi",
                "FI": "Finnland",
                "FR": "Frankreich",
                "GF": "Französisch-Guayana",
                "PF": "Französisch Polynesien",
                "TF": "Südfranzösische Territorien",
                "DJ": "Dschibuti",
                "GA": "Gabun",
                "GE": "Georgien",
                "GM": "Gambia",
                "PS": "Staat Palästina",
                "DE": "Deutschland",
                "GH": "Ghana",
                "GI": "Gibraltar",
                "KI": "Kiribati",
                "GR": "Griechenland",
                "GL": "Grönland",
                "GD": "Grenada",
                "GP": "Guadeloupe",
                "GU": "Guam",
                "GT": "Guatemala",
                "GN": "Guinea",
                "GY": "Guyana",
                "HT": "Haiti",
                "HM": "Heard und McDonald-Inseln",
                "VA": "Vatikanstadt",
                "HN": "Honduras",
                "HK": "Hongkong",
                "HU": "Ungarn",
                "IS": "Island",
                "IN": "Indien",
                "ID": "Indonesien",
                "IR": "Iran",
                "IQ": "Irak",
                "IE": "Irland",
                "IL": "Israel",
                "IT": "Italien",
                "CI": "Cote d'Ivoire",
                "JM": "Jamaika",
                "JP": "Japan",
                "KZ": "Kasachstan",
                "JO": "Jordanien",
                "KE": "Kenia",
                "KP": "Nordkorea",
                "KR": "Südkorea",
                "KW": "Kuwait",
                "KG": "Kirgistan",
                "LA": "Laos",
                "LB": "Libanon",
                "LS": "Lesotho",
                "LV": "Lettland",
                "LR": "Liberia",
                "LY": "Libyen",
                "LI": "Liechtenstein",
                "LT": "Litauen",
                "LU": "Luxemburg",
                "MO": "Macao",
                "MG": "Madagaskar",
                "MW": "Malawi",
                "MY": "Malaysia",
                "MV": "Malediven",
                "ML": "Mali",
                "MT": "Malta",
                "MQ": "Martinique",
                "MR": "Mauretanien",
                "MU": "Mauritius",
                "MX": "Mexiko",
                "MC": "Monaco",
                "MN": "Mongolei",
                "MD": "Moldawien",
                "ME": "Montenegro",
                "MS": "Montserrat",
                "MA": "Marokko",
                "MZ": "Mosambik",
                "OM": "Oman",
                "NA": "Namibia",
                "NR": "Nauru",
                "NP": "Nepal",
                "NL": "Niederlande",
                "CW": "Curacao",
                "AW": "Aruba",
                "SX": "Sint Maarten (niederländischen Teil)",
                "BQ": "Bonaire",
                "NC": "Neu-Kaledonien",
                "VU": "Vanuatu",
                "NZ": "Neuseeland",
                "NI": "Nicaragua",
                "NE": "Niger",
                "NG": "Nigeria",
                "NU": "Niue",
                "NF": "Norfolkinsel",
                "NO": "Norwegen",
                "MP": "Nördliche Marianen",
                "UM": "United States Minor Outlying Islands",
                "FM": "Föderierte Staaten von Mikronesien",
                "MH": "Marshallinseln",
                "PW": "Palau",
                "PK": "Pakistan",
                "PA": "Panama",
                "PG": "Papua Neu-Guinea",
                "PY": "Paraguay",
                "PE": "Peru",
                "PH": "Philippinen",
                "PN": "Pitcairn",
                "PL": "Polen",
                "PT": "Portugal",
                "GW": "Guinea-Bissau",
                "TL": "Timor-Leste",
                "PR": "Puerto Rico",
                "QA": "Katar",
                "RE": "La Réunion",
                "RO": "Rumänien",
                "RU": "Russische Föderation",
                "RW": "Ruanda",
                "BL": "Collectivité de Saint-Barthélemy",
                "SH": "St. Helena",
                "KN": "St. Kitts und Nevis",
                "AI": "Anguilla",
                "LC": "St. Lucia",
                "MF": "Saint Martin (Französisch Teil)",
                "PM": "Saint-Pierre und Miquelon",
                "VC": "St. Vincent und die Grenadinen",
                "SM": "San Marino",
                "ST": "Sao Tome und Principe",
                "SA": "Saudi Arabien",
                "SN": "Senegal",
                "RS": "Serbien",
                "SC": "Seychellen",
                "SL": "Sierra Leone",
                "SG": "Singapur",
                "SK": "Slowakei",
                "VN": "Vietnam",
                "SI": "Slowenien",
                "SO": "Somalia",
                "ZA": "Südafrika",
                "ZW": "Zimbabwe",
                "ES": "Spanien",
                "SS": "Südsudan",
                "SD": "Sudan",
                "EH": "Westsahara",
                "SR": "Suriname",
                "SJ": "Svalbard",
                "SZ": "Swasiland",
                "SE": "Schweden",
                "CH": "Schweiz",
                "SY": "Syrische Arabische Republik",
                "TJ": "Tadschikistan",
                "TH": "Thailand",
                "TG": "Republik Togo",
                "TK": "Tokelau",
                "TO": "Tonga",
                "TT": "Trinidad und Tobago",
                "AE": "Vereinigte Arabische Emirate",
                "TN": "Tunesien",
                "TR": "Türkei",
                "TM": "Turkmenistan",
                "TC": "Turks- und Caicosinseln",
                "TV": "Tuvalu",
                "UG": "Uganda",
                "UA": "Ukraine",
                "MK": "Mazedonien, die ehemalige jugoslawische Republik",
                "EG": "Ägypten",
                "GB": "Großbritannien",
                "GG": "Guernsey",
                "JE": "Jersey",
                "IM": "Isle of Man",
                "TZ": "Vereinigte Republik Tansania",
                "US": "Vereinigte Staaten",
                "VI": "US Jungferninseln",
                "BF": "Burkina Faso",
                "UY": "Uruguay",
                "UZ": "Usbekistan",
                "VE": "Venezuela",
                "WF": "Wallis und Futuna",
                "WS": "Samoa",
                "YE": "Jemen",
                "ZM": "Sambia",
                "XK": "Kosovo",
                "undefined": "Bitte wählen..."
            }
        }
    }
}
