import React from "react";
import PropTypes from 'prop-types';
import ASectionTitle from "../Atoms/ASectionTitle";
import MCheckbox from "../Molecule/MCheckbox";
import {useTranslation} from "react-i18next";
import uniqueId from "lodash/uniqueId";
import AErrorMessage from "../Atoms/AErrorMessage";

const OTermsAccept = ({triggerValidation, register, errors, onFirstDemand}) => {
    const {t} = useTranslation();

    return (
        <div className="col-12 pb-5">
            <ASectionTitle title={t('terms_accepted.section_title')}/>
            <MCheckbox inputId={`m-checkbox_${uniqueId()}`}
                       name="termsAccepted"
                       triggerValidation={triggerValidation}
                       register={register}
                       onFirstDemand={onFirstDemand}
            />
            <div className="row">
                <AErrorMessage error={errors?.termsAccepted} colWidth={'col-12'}/>
            </div>
        </div>
    )
};

OTermsAccept.propTypes = {
  triggerValidation: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.object,
};

export default OTermsAccept;
