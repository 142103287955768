import React from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/pro-light-svg-icons'
import {faPencilAlt} from '@fortawesome/pro-light-svg-icons'

const editIcon = <FontAwesomeIcon icon={faPencilAlt}/>;
const checkmarkIcon = <FontAwesomeIcon icon={faCheck}/>;

const AInput = ({name, inputId, error, triggerValidation, onBlur, onChange, register}) => {
    return (
        <div className='col-12 col-md-6'>
            <div className='row'>
                <input className={`${error ? 'error' : 'valid'} col-12 m-input__input`}
                       id={inputId}
                       name={name}
                       ref={register}
                       onChange={onChange ?? (() => triggerValidation(name))}
                       onBlur={onBlur}>
                </input>
                {error ? <span className='m-input__icon error'>{editIcon} </span> :
                    <span className='m-input__icon valid'>{checkmarkIcon} </span>}
            </div>
        </div>
    )
};

AInput.propTypes = {
  name: PropTypes.string,
  inputId: PropTypes.string,
  error: PropTypes.object,
  triggerValidation: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  register: PropTypes.func,
};

export default AInput;
