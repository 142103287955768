import React from "react";
import PropTypes from 'prop-types';

const MRadioButton = ({id, value, name, error, label, description, register, triggerValidation}) => {
    return (
        <div className="m-radio-button col-12 col-md-6">
            <input id={id} value={value} type="radio" name={name} ref={register} onChange={() => triggerValidation(name)}/>
            <label className={`${error ? 'error' : ''} m-radio-button_label`} htmlFor={id} data-testid="MRadioButtonLabelTest">
                <div className={`${description ? "arrange-description" : "arrange-label"}`}>
                    {label}
                    {description ? (<div className='description'>{description}</div>) : null}
                </div>
            </label>
        </div>
    );
};

MRadioButton.propTypers = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  description: PropTypes.string,
  register: PropTypes.func,
  triggerValidation: PropTypes.func,
};

export default MRadioButton;
