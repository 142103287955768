const nationalities = [
  {isoId: '4', countryCode: 'AF', altName: 'Afghanistan'},
  {isoId: '8', countryCode: 'AL', altName: 'Albanien Albania'},
  {isoId: '12', countryCode: 'DZ', altName: 'Algerien Algeria'},
  {
    isoId: '16',
    countryCode: 'AS',
    altName: 'Amerikanischen Samoa-Inseln American Samoa',
  },
  {isoId: '20', countryCode: 'AD', altName: 'Andorra'},
  {isoId: '24', countryCode: 'AO', altName: 'Angola'},
  {
    isoId: '28',
    countryCode: 'AG',
    altName: 'Antigua and Barbuda Antigua und Barbuda',
  },
  {isoId: '31', countryCode: 'AZ', altName: 'Azerbaijan Aserbaidschan'},
  {isoId: '32', countryCode: 'AR', altName: 'Argentina Argentinien'},
  {isoId: '36', countryCode: 'AU', altName: 'Australia Australien'},
  {isoId: '40', countryCode: 'AT', altName: 'Austria Österreich'},
  {isoId: '44', countryCode: 'BS', altName: 'Bahamas'},
  {isoId: '48', countryCode: 'BH', altName: 'Bahrain Bahrein'},
  {isoId: '50', countryCode: 'BD', altName: 'Bangladesh Bangladesch'},
  {isoId: '51', countryCode: 'AM', altName: 'Armenia Armenien'},
  {isoId: '52', countryCode: 'BB', altName: 'Barbados'},
  {isoId: '56', countryCode: 'BE', altName: 'Belgium Belgien'},
  {isoId: '60', countryCode: 'BM', altName: 'Bermuda'},
  {isoId: '64', countryCode: 'BT', altName: 'Bhutan'},
  {isoId: '68', countryCode: 'BO', altName: 'Bolivia Bolivien'},
  {
    isoId: '70',
    countryCode: 'BA',
    altName: 'Bosnia and Herzegovina Bosnien und Herzegowina',
  },
  {isoId: '72', countryCode: 'BW', altName: 'Botswana'},
  {isoId: '74', countryCode: 'BV', altName: 'Bouvet Island Bouvet-Insel'},
  {isoId: '76', countryCode: 'BR', altName: 'Brazil Brasilien'},
  {isoId: '84', countryCode: 'BZ', altName: 'Belize'},
  {
    isoId: '92',
    countryCode: 'VG',
    altName: 'British Virgin Islands Britische Jungferninseln',
  },
  {isoId: '96', countryCode: 'BN', altName: 'Brunei Darussalam'},
  {isoId: '100', countryCode: 'BG', altName: 'Bulgaria Bulgarien'},
  {isoId: '104', countryCode: 'MM', altName: 'Myanmar'},
  {isoId: '108', countryCode: 'BI', altName: 'Burundi'},
  {isoId: '112', countryCode: 'BY', altName: 'Belarus Weißrussland'},
  {isoId: '116', countryCode: 'KH', altName: 'Cambodia Kambodscha'},
  {isoId: '120', countryCode: 'CM', altName: 'Cameroon Kamerun'},
  {isoId: '124', countryCode: 'CA', altName: 'Canada Kanada'},
  {isoId: '132', countryCode: 'CV', altName: 'Cape Verde Kap Verde'},
  {isoId: '136', countryCode: 'KY', altName: 'Cayman Islands Cayman Inseln'},
  {
    isoId: '140',
    countryCode: 'CF',
    altName: 'Central African Republic Zentralafrikanische Republik',
  },
  {isoId: '144', countryCode: 'LK', altName: 'Sri Lanka'},
  {isoId: '148', countryCode: 'TD', altName: 'Chad Tschad'},
  {isoId: '152', countryCode: 'CL', altName: 'Chile'},
  {isoId: '156', countryCode: 'CN', altName: 'China'},
  {isoId: '158', countryCode: 'TW', altName: 'Taiwan'},
  {
    isoId: '162',
    countryCode: 'CX',
    altName: 'Christmas Island Weihnachtsinsel',
  },
  {isoId: '166', countryCode: 'CC', altName: 'Cocos (Keeling) Islands'},
  {isoId: '170', countryCode: 'CO', altName: 'Colombia Kolumbien'},
  {isoId: '174', countryCode: 'KM', altName: 'Comoros Komoren'},
  {isoId: '175', countryCode: 'YT', altName: 'Mayotte'},
  {isoId: '178', countryCode: 'CG', altName: 'Congo Kongo'},
  {
    isoId: '180',
    countryCode: 'CD',
    altName: 'Democratic Republic of the Congo Demokratische Republik Kongo',
  },
  {isoId: '184', countryCode: 'CK', altName: 'Cook Islands Cookinseln'},
  {isoId: '188', countryCode: 'CR', altName: 'Costa Rica'},
  {isoId: '191', countryCode: 'HR', altName: 'Croatia Kroatien'},
  {isoId: '192', countryCode: 'CU', altName: 'Cuba Kuba'},
  {isoId: '196', countryCode: 'CY', altName: 'Cyprus Zypern'},
  {
    isoId: '203',
    countryCode: 'CZ',
    altName: 'Czech Republic Tschechische Republik',
  },
  {isoId: '204', countryCode: 'BJ', altName: 'Benin'},
  {isoId: '208', countryCode: 'DK', altName: 'Denmark Dänemark'},
  {isoId: '212', countryCode: 'DM', altName: 'Dominica'},
  {
    isoId: '214',
    countryCode: 'DO',
    altName: 'Dominican Republic Dominikanische Republik',
  },
  {isoId: '218', countryCode: 'EC', altName: 'Ecuador'},
  {isoId: '222', countryCode: 'SV', altName: 'El Salvador'},
  {
    isoId: '226',
    countryCode: 'GQ',
    altName: 'Equatorial Guinea Äquatorialguinea',
  },
  {isoId: '231', countryCode: 'ET', altName: 'Ethiopia Äthiopien'},
  {isoId: '232', countryCode: 'ER', altName: 'Eritrea'},
  {isoId: '233', countryCode: 'EE', altName: 'Estonia Estland'},
  {isoId: '234', countryCode: 'FO', altName: 'Faroe Islands Färöer Inseln'},
  {
    isoId: '238',
    countryCode: 'FK',
    altName: 'Falkland Islands (Malvinas) Falklandinseln (Malvinas)',
  },
  {
    isoId: '239',
    countryCode: 'GS',
    altName: 'South Georgia and the South Sandwich Islands Süd-Georgien und die südlichen Sandwich-Inseln',
  },
  {isoId: '242', countryCode: 'FJ', altName: 'Fiji Fidschi'},
  {isoId: '246', countryCode: 'FI', altName: 'Finland Finnland'},
  {isoId: '250', countryCode: 'FR', altName: 'France Frankreich'},
  {
    isoId: '254',
    countryCode: 'GF',
    altName: 'French Guiana Französisch-Guayana',
  },
  {
    isoId: '258',
    countryCode: 'PF',
    altName: 'French Polynesia Französisch Polynesien',
  },
  {isoId: '262', countryCode: 'DJ', altName: 'Djibouti Dschibuti'},
  {isoId: '266', countryCode: 'GA', altName: 'Gabon Gabun'},
  {isoId: '268', countryCode: 'GE', altName: 'Georgia Georgien'},
  {isoId: '270', countryCode: 'GM', altName: 'Gambia'},
  {isoId: '276', countryCode: 'DE', altName: 'Germany Deutschland'},
  {isoId: '288', countryCode: 'GH', altName: 'Ghana'},
  {isoId: '292', countryCode: 'GI', altName: 'Gibraltar'},
  {isoId: '296', countryCode: 'KI', altName: 'Kiribati'},
  {isoId: '300', countryCode: 'GR', altName: 'Greece Griechenland'},
  {isoId: '304', countryCode: 'GL', altName: 'Greenland Grönland'},
  {isoId: '308', countryCode: 'GD', altName: 'Grenada'},
  {isoId: '312', countryCode: 'GP', altName: 'Guadeloupe'},
  {isoId: '316', countryCode: 'GU', altName: 'Guam'},
  {isoId: '320', countryCode: 'GT', altName: 'Guatemala'},
  {isoId: '324', countryCode: 'GN', altName: 'Guinea'},
  {isoId: '328', countryCode: 'GY', altName: 'Guyana'},
  {isoId: '332', countryCode: 'HT', altName: 'Haiti'},
  {
    isoId: '334',
    countryCode: 'HM',
    altName: 'Heard Island and McDonald Islands Heard und McDonald-Inseln',
  },
  {
    isoId: '336',
    countryCode: 'VA',
    altName: 'Holy See (Vatican City State) Vatikanstadt',
  },
  {isoId: '340', countryCode: 'HN', altName: 'Honduras'},
  {isoId: '344', countryCode: 'HK', altName: 'Hong Kong Hongkong'},
  {isoId: '348', countryCode: 'HU', altName: 'Hungary Ungarn'},
  {isoId: '352', countryCode: 'IS', altName: 'Iceland Island'},
  {isoId: '356', countryCode: 'IN', altName: 'India Indien'},
  {isoId: '360', countryCode: 'ID', altName: 'Indonesia Indonesien'},
  {isoId: '364', countryCode: 'IR', altName: 'Iran  Islamic Republic of Iran'},
  {isoId: '368', countryCode: 'IQ', altName: 'Iraq Irak'},
  {isoId: '372', countryCode: 'IE', altName: 'Ireland Irland'},
  {isoId: '376', countryCode: 'IL', altName: 'Israel'},
  {isoId: '380', countryCode: 'IT', altName: 'Italy Italien'},
  {isoId: '384', countryCode: 'CI', altName: 'Cote d\'Ivoire'},
  {isoId: '388', countryCode: 'JM', altName: 'Jamaica Jamaika'},
  {isoId: '392', countryCode: 'JP', altName: 'Japan'},
  {isoId: '398', countryCode: 'KZ', altName: 'Kazakhstan Kasachstan'},
  {isoId: '400', countryCode: 'JO', altName: 'Jordan Jordanien'},
  {isoId: '404', countryCode: 'KE', altName: 'Kenya Kenia'},
  {
    isoId: '408',
    countryCode: 'KP',
    altName: 'Korea  Democratic People\'s Republic of Nordkorea',
  },
  {isoId: '410', countryCode: 'KR', altName: 'Korea  Republic of Südkorea'},
  {isoId: '414', countryCode: 'KW', altName: 'Kuwait'},
  {isoId: '417', countryCode: 'KG', altName: 'Kyrgyzstan Kirgistan'},
  {
    isoId: '418',
    countryCode: 'LA',
    altName: 'Lao People\'s Democratic Republic Laos',
  },
  {isoId: '422', countryCode: 'LB', altName: 'Lebanon Libanon'},
  {isoId: '426', countryCode: 'LS', altName: 'Lesotho'},
  {isoId: '428', countryCode: 'LV', altName: 'Latvia Lettland'},
  {isoId: '430', countryCode: 'LR', altName: 'Liberia'},
  {isoId: '434', countryCode: 'LY', altName: 'Libya Libyen'},
  {isoId: '438', countryCode: 'LI', altName: 'Liechtenstein'},
  {isoId: '440', countryCode: 'LT', altName: 'Lithuania Litauen'},
  {isoId: '442', countryCode: 'LU', altName: 'Luxembourg Luxemburg'},
  {isoId: '446', countryCode: 'MO', altName: 'Macao'},
  {isoId: '450', countryCode: 'MG', altName: 'Madagascar Madagaskar'},
  {isoId: '454', countryCode: 'MW', altName: 'Malawi'},
  {isoId: '458', countryCode: 'MY', altName: 'Malaysia'},
  {isoId: '462', countryCode: 'MV', altName: 'Maldives Malediven'},
  {isoId: '466', countryCode: 'ML', altName: 'Mali'},
  {isoId: '470', countryCode: 'MT', altName: 'Malta'},
  {isoId: '474', countryCode: 'MQ', altName: 'Martinique'},
  {isoId: '478', countryCode: 'MR', altName: 'Mauritania Mauretanien'},
  {isoId: '480', countryCode: 'MU', altName: 'Mauritius'},
  {isoId: '484', countryCode: 'MX', altName: 'Mexico Mexiko'},
  {isoId: '492', countryCode: 'MC', altName: 'Monaco'},
  {isoId: '496', countryCode: 'MN', altName: 'Mongolia Mongolei'},
  {isoId: '498', countryCode: 'MD', altName: 'Moldova  Republic of Moldawien'},
  {isoId: '499', countryCode: 'ME', altName: 'Montenegro'},
  {isoId: '500', countryCode: 'MS', altName: 'Montserrat'},
  {isoId: '504', countryCode: 'MA', altName: 'Morocco Marokko'},
  {isoId: '508', countryCode: 'MZ', altName: 'Mozambique Mosambik'},
  {isoId: '512', countryCode: 'OM', altName: 'Oman'},
  {isoId: '516', countryCode: 'NA', altName: 'Namibia'},
  {isoId: '520', countryCode: 'NR', altName: 'Nauru'},
  {isoId: '524', countryCode: 'NP', altName: 'Nepal'},
  {isoId: '528', countryCode: 'NL', altName: 'Netherlands Niederlande'},
  {isoId: '531', countryCode: 'CW', altName: 'Curacao'},
  {
    isoId: '534',
    countryCode: 'SX',
    altName: 'Sint Maarten (Dutch part) Sint Maarten (niederländischen Teil)',
  },
  {isoId: '535', countryCode: 'BQ', altName: 'Bonaire'},
  {isoId: '540', countryCode: 'NC', altName: 'New Caledonia Neu-Kaledonien'},
  {isoId: '548', countryCode: 'VU', altName: 'Vanuatu'},
  {isoId: '554', countryCode: 'NZ', altName: 'New Zealand Neuseeland'},
  {isoId: '558', countryCode: 'NI', altName: 'Nicaragua'},
  {isoId: '562', countryCode: 'NE', altName: 'Niger'},
  {isoId: '566', countryCode: 'NG', altName: 'Nigeria'},
  {isoId: '574', countryCode: 'NF', altName: 'Norfolk Island Norfolkinsel'},
  {isoId: '578', countryCode: 'NO', altName: 'Norway Norwegen'},
  {
    isoId: '580',
    countryCode: 'MP',
    altName: 'Northern Mariana Islands Nördliche Marianen',
  },
  {
    isoId: '581',
    countryCode: 'UM',
    altName: 'United States Minor Outlying Islands',
  },
  {isoId: '584', countryCode: 'MH', altName: 'Marshall Islands Marshallinseln'},
  {isoId: '585', countryCode: 'PW', altName: 'Palau'},
  {isoId: '586', countryCode: 'PK', altName: 'Pakistan'},
  {isoId: '591', countryCode: 'PA', altName: 'Panama'},
  {
    isoId: '598',
    countryCode: 'PG',
    altName: 'Papua New Guinea Papua Neu-Guinea',
  },
  {isoId: '600', countryCode: 'PY', altName: 'Paraguay'},
  {isoId: '604', countryCode: 'PE', altName: 'Peru'},
  {isoId: '608', countryCode: 'PH', altName: 'Philippines Philippinen'},
  {isoId: '616', countryCode: 'PL', altName: 'Poland Polen'},
  {isoId: '620', countryCode: 'PT', altName: 'Portugal'},
  {isoId: '624', countryCode: 'GW', altName: 'Guinea-Bissau'},
  {isoId: '626', countryCode: 'TL', altName: 'Timor-Leste'},
  {isoId: '630', countryCode: 'PR', altName: 'Puerto Rico'},
  {isoId: '634', countryCode: 'QA', altName: 'Qatar Katar'},
  {isoId: '638', countryCode: 'RE', altName: 'Reunion La Réunion'},
  {isoId: '642', countryCode: 'RO', altName: 'Romania Rumänien'},
  {
    isoId: '643',
    countryCode: 'RU',
    altName: 'Russian Federation Russische Föderation',
  },
  {isoId: '646', countryCode: 'RW', altName: 'Rwanda Ruanda'},
  {
    isoId: '652',
    countryCode: 'BL',
    altName: 'Saint Barthelemy Collectivité de Saint-Barthélemy',
  },
  {
    isoId: '659',
    countryCode: 'KN',
    altName: 'Saint Kitts and Nevis St. Kitts und Nevis',
  },
  {isoId: '660', countryCode: 'AI', altName: 'Anguilla'},
  {isoId: '662', countryCode: 'LC', altName: 'Saint Lucia St. Lucia'},
  {
    isoId: '663',
    countryCode: 'MF',
    altName: 'Saint Martin (French part) Saint Martin (Französisch Teil)',
  },
  {
    isoId: '666',
    countryCode: 'PM',
    altName: 'Saint Pierre and Miquelon Saint-Pierre und Miquelon',
  },
  {
    isoId: '670',
    countryCode: 'VC',
    altName: 'Saint Vincent and the Grenadines St. Vincent und die Grenadinen',
  },
  {isoId: '674', countryCode: 'SM', altName: 'San Marino'},
  {
    isoId: '678',
    countryCode: 'ST',
    altName: 'Sao Tome and Principe Sao Tome und Principe',
  },
  {isoId: '682', countryCode: 'SA', altName: 'Saudi Arabia Saudi Arabien'},
  {isoId: '686', countryCode: 'SN', altName: 'Senegal'},
  {isoId: '688', countryCode: 'RS', altName: 'Serbia Serbien'},
  {isoId: '690', countryCode: 'SC', altName: 'Seychelles Seychellen'},
  {isoId: '694', countryCode: 'SL', altName: 'Sierra Leone'},
  {isoId: '702', countryCode: 'SG', altName: 'Singapore Singapur'},
  {isoId: '703', countryCode: 'SK', altName: 'Slovakia Slowakei'},
  {isoId: '704', countryCode: 'VN', altName: 'Viet Nam Vietnam'},
  {isoId: '705', countryCode: 'SI', altName: 'Slovenia Slowenien'},
  {isoId: '706', countryCode: 'SO', altName: 'Somalia'},
  {isoId: '710', countryCode: 'ZA', altName: 'South Africa Südafrika'},
  {isoId: '716', countryCode: 'ZW', altName: 'Zimbabwe'},
  {isoId: '724', countryCode: 'ES', altName: 'Spain Spanien'},
  {isoId: '728', countryCode: 'SS', altName: 'South Sudan Südsudan'},
  {isoId: '729', countryCode: 'SD', altName: 'Sudan'},
  {isoId: '732', countryCode: 'EH', altName: 'Western Sahara Westsahara'},
  {isoId: '740', countryCode: 'SR', altName: 'Suriname'},
  {isoId: '744', countryCode: 'SJ', altName: 'Svalbard and Jan Mayen Svalbard'},
  {isoId: '748', countryCode: 'SZ', altName: 'Swaziland Swasiland'},
  {isoId: '752', countryCode: 'SE', altName: 'Sweden Schweden'},
  {isoId: '756', countryCode: 'CH', altName: 'Switzerland Schweiz'},
  {
    isoId: '760',
    countryCode: 'SY',
    altName: 'Syrian Arab Republic Syrische Arabische Republik',
  },
  {isoId: '762', countryCode: 'TJ', altName: 'Tajikistan Tadschikistan'},
  {isoId: '764', countryCode: 'TH', altName: 'Thailand'},
  {isoId: '768', countryCode: 'TG', altName: 'Togo Republik Togo'},
  {isoId: '776', countryCode: 'TO', altName: 'Tonga'},
  {
    isoId: '780',
    countryCode: 'TT',
    altName: 'Trinidad and Tobago Trinidad und Tobago',
  },
  {
    isoId: '784',
    countryCode: 'AE',
    altName: 'United Arab Emirates Vereinigte Arabische Emirate',
  },
  {isoId: '788', countryCode: 'TN', altName: 'Tunisia Tunesien'},
  {isoId: '792', countryCode: 'TR', altName: 'Turkey Türkei'},
  {isoId: '795', countryCode: 'TM', altName: 'Turkmenistan'},
  {
    isoId: '796',
    countryCode: 'TC',
    altName: 'Turks and Caicos Islands Turks- und Caicosinseln',
  },
  {isoId: '800', countryCode: 'UG', altName: 'Uganda'},
  {isoId: '804', countryCode: 'UA', altName: 'Ukraine'},
  {
    isoId: '807',
    countryCode: 'MK',
    altName: 'Macedonia  the Former Yugoslav Republic of Mazedonien  die ehemalige jugoslawische Republik',
  },
  {isoId: '818', countryCode: 'EG', altName: 'Egypt Ägypten'},
  {isoId: '826', countryCode: 'GB', altName: 'United Kingdom Großbritannien'},
  {isoId: '831', countryCode: 'GG', altName: 'Guernsey'},
  {isoId: '832', countryCode: 'JE', altName: 'Jersey'},
  {isoId: '833', countryCode: 'IM', altName: 'Isle of Man'},
  {
    isoId: '834',
    countryCode: 'TZ',
    altName: 'United Republic of Tanzania Vereinigte Republik Tansania',
  },
  {
    isoId: '840',
    countryCode: 'US',
    altName: 'United States Vereinigte Staaten',
  },
  {
    isoId: '850',
    countryCode: 'VI',
    altName: 'US Virgin Islands US Jungferninseln',
  },
  {isoId: '854', countryCode: 'BF', altName: 'Burkina Faso'},
  {isoId: '858', countryCode: 'UY', altName: 'Uruguay'},
  {isoId: '860', countryCode: 'UZ', altName: 'Uzbekistan Usbekistan'},
  {isoId: '862', countryCode: 'VE', altName: 'Venezuela'},
  {isoId: '882', countryCode: 'WS', altName: 'Samoa'},
  {isoId: '887', countryCode: 'YE', altName: 'Yemen Jemen'},
  {isoId: '894', countryCode: 'ZM', altName: 'Zambia Sambia'},
  {isoId: '1001', countryCode: 'XK', altName: 'Kosovo'},
];

const isValidNationality = (option) => {
  if (!option || !option.hasOwnProperty('value')) {
    return false;
  }

  const value = option.value;
  const nationality = nationalities.find((nationality) => {
    return nationality.countryCode === value;
  });
  return !!nationality;
};


export default nationalities;

export {
  isValidNationality,
};
