import React from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const ABlockInformation = ({message, color}) => {
    const {t} = useTranslation();

    return (
        <div className={'col-12 a-block-information_wrapper'}>
            <div
                className={`a-block-information_container ${color}`}>
                {t(message)}
            </div>
        </div>
    )
};


ABlockInformation.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
};

export default ABlockInformation;
