import * as Yup from 'yup';
import {
  ibanSchema,
  tenantSchema,
} from './ValidationSchema';

export default Yup.object().shape({
  firstTenant: tenantSchema(true),
  bankAccount: Yup.object().shape({
    iban: ibanSchema,
    bankName: Yup.string().required('validation.required'),
    holderName: Yup.string().required('validation.required'),
  }),
  payment: Yup.string().required('validation.required'),
  termsAccepted: Yup.bool().oneOf([true], 'validation.error.terms_accepted'),
});
