import React from "react";
import ASectionTitle from "../Atoms/ASectionTitle";
import {useTranslation} from "react-i18next";
import MInput from "../Molecule/MInput";
import uniqueId from "lodash/uniqueId";
import {setBankName} from "../../utilities/bankName";

const OBankAccount = ({...methods}) => {
    const {t} = useTranslation();
    const error = methods.errors?.bankAccount;

    return(
        <div className="col-12 pb-5">
            <ASectionTitle title={t('bank_account.section_title')}
                           description={t('bank_account.description')}/>
            <MInput {...methods}
                    error={error?.holderName}
                    label={t("label.account_holder")}
                    name="bankAccount.holderName"
                    inputId={`m-input_${uniqueId()}`}/>
            <MInput {...methods}
                    error={error?.iban}
                    label={t("label.iban")}
                    name="bankAccount.iban"
                    inputId={`m-input_${uniqueId()}`}
                    onBlur={() => {
                        if (!error?.iban) {
                            setBankName(methods.getValues, methods.setValue).then();
                        }
                    }}/>
            <MInput {...methods}
                    error={error?.bankName}
                    label={t("label.bank_name")}
                    name="bankAccount.bankName"
                    inputId={`m-input_${uniqueId()}`}/>
        </div>
    )
};

export default OBankAccount;
