import React from "react";
import PropTypes from 'prop-types';

const ADataTitle = ({dataTitle, nameTenant}) => {
    return (
        <div className="row">
            <div className="col-12 a-data-title__wrapper">
                <span className='a-data-title__title'>{dataTitle}</span>
                {nameTenant ? <span className='a-data-title__tenant-name'> ( {nameTenant} ) </span> : null}
            </div>
        </div>
    );
};

ADataTitle.propTypes = {
  dataTitle: PropTypes.string,
  nameTenant: PropTypes.string,
};

export default ADataTitle;
