import React from "react";
import PropTypes from 'prop-types';
import ALabel from "../Atoms/ALabel";
import AErrorMessage from "../Atoms/AErrorMessage";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/pro-light-svg-icons'
import {faPencilAlt} from '@fortawesome/pro-light-svg-icons'

const editIcon = <FontAwesomeIcon size='2x' icon={faPencilAlt}/>;
const checkmarkIcon = <FontAwesomeIcon size='2x' icon={faCheck}/>;

const MAddressField = ({label, inputId, colFirstInput, colSecondInput, nameFirstInput, nameSecondInput, errorFirstInput, errorSecondInput, onChange, register, triggerValidation}) => {
    return (
        <div className="row">
            <ALabel inputId={inputId} label={label}/>
            <div className="col-12 col-md-6">
                <div className="row">
                    <div className={colFirstInput}>
                        <div className='row pl-0 pr-0 pr-md-3 pb-3 '>
                            <input id={inputId}
                                   className={`${errorFirstInput ? 'error' : 'valid'} col-12 m-address-field_input`}
                                   name={nameFirstInput}
                                   ref={register}
                                   onChange={onChange ?? (() => triggerValidation(nameFirstInput))}/>
                            {errorFirstInput ?
                                <div>
                                    <span className='pr-md-3 m-address-field__icon error'>{editIcon} </span>
                                    <AErrorMessage error={errorFirstInput} colWidth={'col-12'}/>
                                </div> :
                                <span className='pr-md-3 m-address-field__icon valid'>{checkmarkIcon} </span>}
                        </div>
                    </div>

                    <div className={colSecondInput}>
                        <div className='row pr-0 pl-0 pb-3'>
                            <input
                                className={`${errorSecondInput ? 'error' : 'valid'} col-12 m-address-field_input`}
                                name={nameSecondInput}
                                ref={register}
                                onChange={onChange ?? (() => triggerValidation(nameSecondInput))}/>
                            {errorSecondInput ?
                                <div>
                                    <span className='m-address-field__icon error'>{editIcon} </span>
                                    <AErrorMessage error={errorSecondInput} colWidth={'col-12'}/>
                                </div> :
                                <span className='m-address-field__icon valid'>{checkmarkIcon} </span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


MAddressField.propTypes = {
    label: PropTypes.string,
    inputId: PropTypes.string,
    colFirstInput: PropTypes.string,
    colSecondInput: PropTypes.string,
    nameFirstInput: PropTypes.string,
    nameSecondInput: PropTypes.string,
    errorFirstInput: PropTypes.object,
    errorSecondInput: PropTypes.object,
    onChange: PropTypes.func,
    register: PropTypes.func,
    triggerValidation: PropTypes.func,
};

export default MAddressField;
