import React from "react";
import PropTypes from 'prop-types';

const ASectionTitle = ({title, description, descriptionEnd}) => {
    return (
        <div className="row">
            <div className="col-12 a-section-title_wrapper">
                <div className="a-section-title">
                    <span>{title}</span>
                    <hr className="a-section-title_line"/>
                </div>
                {description && descriptionEnd ? <div className="a-section_description"> {description} <span
                        className='a-section_description-bold'> {descriptionEnd}</span></div> :
                    description ? <div className="a-section_description"> {description} </div> : null}
            </div>
        </div>
    )
};

ASectionTitle.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionEnd: PropTypes.string,
};

export default ASectionTitle;
